import React from "react";
import { Link } from "react-router-dom";
import { IoMdTrain } from "react-icons/io";
import { TbRouteSquare } from "react-icons/tb";
import { BsTicketDetailed } from "react-icons/bs";
import { RiInformationFill } from "react-icons/ri";
import { TbNotes } from "react-icons/tb";

function Home() {
  const cards = [
    {
      title: "PNR Status",
      content: "Check your PNR status here",
      path: "/pnr",
      icons: [IoMdTrain, BsTicketDetailed],
      position: 1,
    },
    {
      title: "Train Route",
      content: "Search and view train routes",
      path: "/train-route",
      icons: [IoMdTrain, TbRouteSquare],
      position: 2,
    },
    {
      title: "Live Train",
      content: "Track live train status",
      path: "/live-train",
      icons: [IoMdTrain, TbRouteSquare],
      position: 3,
      isBlinking: true,
    },
    {
      title: "Train Information",
      content: "Get detailed train information",
      path: "/train-info",
      icons: [IoMdTrain, RiInformationFill, TbNotes],
      position: 4,
    },
  ];

  const getCardColor = (position) => {
    const colors = generateColors();
    return colors[position - 1];
  };

  const generateColors = () => {
    const colors = [
      "#f44336",
      "#2196f3",
      "#4caf50",
      "#ff9800",
      "#9c27b0",
      "#673ab7",
      "#ff5722",
      "#607d8b",
      "#3f51b5",
      "#ffeb3b",
      "#009688",
      "#e91e63",
      "#03a9f4",
      "#8bc34a",
      "#ff5722",
      "#9e9e9e",
      "#ffc107",
      "#795548",
      "#00bcd4",
      "#cddc39",
      "#ff5722",
      "#607d8b",
      "#673ab7",
      "#8bc34a",
      "#e91e63",
      "#03a9f4",
      "#9c27b0",
      "#ff9800",
      "#4caf50",
      "#2196f3",
    ];
    return colors;
  };

  return (
    <div className="flex flex-col">
      
      <main className="flex-grow p-5">
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-5">
          {cards.map((card) => (
            <Link to={card.path} key={card.position} className="no-underline text-inherit">
              <div
                className={`border border-gray-300 rounded-lg p-5 shadow-md transition-colors duration-300 cursor-pointer ${card.isBlinking ? "animate-pulse" : ""}`}
                style={{ backgroundColor: getCardColor(card.position) }}
              >
                <div className="flex justify-center mb-5">
                  {card.icons.map((Icon, index) => (
                    <Icon key={index} size={48} className="mx-2" />
                  ))}
                </div>
                <div className="text-center mb-3">
                  <h2 className="text-xl font-semibold">{card.title}</h2>
                </div>
                <div className="text-center text-gray-700">
                  <p>{card.content}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </main>
      
    </div>
  );
}

export default Home;
