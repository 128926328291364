import React, { useState } from 'react';
import { RiCopyrightFill } from "react-icons/ri";
import { ImLink } from "react-icons/im";
import ClickSound from "../notification/mixkit-game-balloon-or-bubble-pop-3069.wav"; // Adjust path as necessary

function Footer() {
  const [clickSound] = useState(new Audio(ClickSound)); // Create audio element for the click sound

  const playClickSound = () => {
    clickSound.currentTime = 0; // Rewind to the beginning
    clickSound.play();
  };

  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer className="bg-gray-800 text-white fixed bottom-0 left-0 w-full z-[1000]">
      <div className="flex sm:flex-row justify-center gap-3  items-center py-3">
      <div className=' flex  md:justify-center md:w-1/2'>
        <span className="flex items-center text-xs sm:text-sm md:text-base mr-0 sm:mr-5 mb-2 sm:mb-0">
          <RiCopyrightFill className="mr-1" /> Phoodcry Catering Private Limited &copy; {currentYear}
        </span>
        </div>
        <div className=' flex md:justify-center md:w-1/2'>
        <a href="https://www.cooliewale.in/" className="inline-block p-1 sm:p-2 text-xs sm:text-sm md:text-base bg-red-600 border border-purple-500 rounded-lg transition duration-300 hover:bg-pink-600 focus:outline-none focus:ring-4 focus:ring-blue-300" onClick={playClickSound}>
          <ImLink className="mr-1" /> CoolieWale
        </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
