import React, { useState, useRef } from "react";
import { IoMdTrain } from "react-icons/io";
import { TbRouteSquare } from "react-icons/tb";
import { BiSolidErrorAlt } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClickSound from "../notification/mixkit-game-balloon-or-bubble-pop-3069.wav"; // Adjust path as necessary
import ServerNotification from '../notification/mixkit-clear-announce-tones-2861.wav';
import SuccessNotification from '../notification/mixkit-ice-hockey-sports-buzzer-941.wav';
import ErrorNotification from '../notification/mixkit-wrong-answer-bass-buzzer-948.wav';

function TrainRoute() {
  const [trainNo, setTrainNo] = useState(""); // State to store train number input
  const [routeInfo, setRouteInfo] = useState(null); // State to store API response
  const [error, setError] = useState(null); // State to store error messages

  const clickSoundRef = useRef(new Audio(ClickSound)); // Create audio element for the click sound
  const serverNotificationRef = useRef(new Audio(ServerNotification));
  const successNotificationRef = useRef(new Audio(SuccessNotification));
  const errorNotificationRef = useRef(new Audio(ErrorNotification));

  const handleInputChange = (e) => {
    // Restrict input to 5 digits
    const inputTrainNo = e.target.value.replace(/\D/g, '').slice(0, 5);
    setTrainNo(inputTrainNo);
  };

  const fetchTrainRoute = async () => {
    try {
      // Validate train number length
      if (trainNo.length !== 5) {
        throw new Error("Train number should be exactly 5 digits.");
      }

      // Call backend API
      const response = await fetch(
        `https://trainbackend.cooliewale.in/api/getRoute?trainNo=${trainNo}`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      // Handle "Train not found" error
      if (data.data.data === "Train not found") {
        toast.warn('Train not found.');
        setRouteInfo({ error: "Train not found" });
        playNotificationSound('error');
      } else {
        toast.success(`Train Information obtained for ${data['trainNo']}  & table generated successfully!`);
        setRouteInfo(data.data); // Store API response in state
        playNotificationSound('success');
      }
      setError(null); // Clear any previous errors
    } catch (error) {
      setError(error.message || "Something went wrong.");
      toast.error('Something went wrong.');
      playNotificationSound('error');
    }
  };

  const renderTable = () => {
    if (!routeInfo) {
      return null;
    }

    // Check if there's an error message indicating "Train not found"
    if (routeInfo.error === "Train not found") {
      return (
        <div className="text-red-500 font-bold text-center mt-5">
          <p>
            {" "}
            <BiSolidErrorAlt /> Train not found.
          </p>
        </div>
      );
    }

    // Check if routeInfo.data is undefined or null (handle edge cases)
    if (!routeInfo.data || !Array.isArray(routeInfo.data)) {
      return (
        <div className="text-red-500 font-bold text-center mt-5">
          <p>No route information available.</p>
        </div>
      );
    }

    // Assuming routeInfo contains valid data to display as a table
    return (
      <div className="overflow-y-auto mt-5 md:mb-20">
        <h3 className="text-xl font-bold mb-3">Train Route Information for Train No. {trainNo}</h3>
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 border border-gray-300">Station Name</th>
              <th className="py-2 px-4 border border-gray-300">Station Code</th>
              <th className="py-2 px-4 border border-gray-300">Arrival Time</th>
              <th className="py-2 px-4 border border-gray-300">Departure Time</th>
              <th className="py-2 px-4 border border-gray-300">Distance (km)</th>
              <th className="py-2 px-4 border border-gray-300">Day</th>
              <th className="py-2 px-4 border border-gray-300">Zone</th>
            </tr>
          </thead>
          <tbody>
            {routeInfo.data.map((station, index) => (
              <tr key={index} className={`bg-${index % 2 === 0 ? 'white' : 'gray-100'} hover:bg-gray-200`}>
                <td className="py-2 px-4 border border-gray-300">{station.source_stn_name}</td>
                <td className="py-2 px-4 border border-gray-300">{station.source_stn_code}</td>
                <td className="py-2 px-4 border border-gray-300">{station.arrive}</td>
                <td className="py-2 px-4 border border-gray-300">{station.depart}</td>
                <td className="py-2 px-4 border border-gray-300">{station.distance}</td>
                <td className="py-2 px-4 border border-gray-300">{station.day}</td>
                <td className="py-2 px-4 border border-gray-300">{station.zone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const playNotificationSound = (type) => {
    switch (type) {
      case 'click':
        clickSoundRef.current.currentTime = 0;
        clickSoundRef.current.play();
        break;
      case 'success':
        successNotificationRef.current.play();
        break;
      case 'error':
        errorNotificationRef.current.play();
        break;
      default:
        serverNotificationRef.current.play();
        break;
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-5 flex flex-col min-h-screen">
      <ToastContainer /> {/* Ensure ToastContainer is rendered once in your application */}
      <h2 className="text-center my-5 flex justify-center items-center gap-2">
        <IoMdTrain /> <TbRouteSquare /> Check Train Route
      </h2>
      <div className="flex flex-col items-center mb-5">
        <input
          type="text"
          placeholder="Enter Train Number"
          value={trainNo}
          onChange={handleInputChange}
          maxLength={5} // Restrict input length to 5 characters
          className="p-2 border border-gray-300 rounded mb-3 w-full max-w-xs"
        />
        <button 
          onClick={() => { fetchTrainRoute(); playNotificationSound('click'); }}
          className="p-2 bg-blue-500 text-white rounded w-full max-w-xs hover:bg-blue-700"
        >
          Get Route
        </button>
      </div>
      {error && <p className="text-red-500 text-center mb-5"><BiSolidErrorAlt /> {error}</p>}
      <div className="flex-grow">{renderTable()}</div>
      {/* Audio elements for notification sounds */}
      <audio ref={clickSoundRef} src={ClickSound} style={{ display: "none" }} />
      <audio ref={serverNotificationRef} src={ServerNotification} style={{ display: "none" }} />
      <audio ref={successNotificationRef} src={SuccessNotification} style={{ display: "none" }} />
      <audio ref={errorNotificationRef} src={ErrorNotification} style={{ display: "none" }} />
    </div>
  );
}

export default TrainRoute;
