import React, { useState } from 'react';
import './traininfo.css';
import { IoMdTrain } from 'react-icons/io';
import { RiInformationFill } from 'react-icons/ri';
import { TbNotes } from 'react-icons/tb';
import { BiSolidErrorAlt } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClickSound from "../notification/mixkit-game-balloon-or-bubble-pop-3069.wav";
import ServerNotification from '../notification/mixkit-clear-announce-tones-2861.wav';
import SuccessNotification from '../notification/mixkit-ice-hockey-sports-buzzer-941.wav';
import ErrorNotification from '../notification/mixkit-wrong-answer-bass-buzzer-948.wav';

function TrainInformation() {
  const [trainNo, setTrainNo] = useState(''); // State to store train number input
  const [trainInfo, setTrainInfo] = useState(null); // State to store API response
  const [error, setError] = useState(null); // State to store error messages

  const handleInputChange = (e) => {
    setTrainNo(e.target.value); // Update trainNo state with user input
  };

  const fetchTrainInfo = async () => {
    try {
      // Validate train number length
      if (trainNo.length !== 5) {
        setError('Train number should be exactly 5 digits.');
        playNotificationSound('error');
        return;
      }

      // Call backend API
      const response = await fetch(`https://trainbackend.cooliewale.in/api/getTrainInfo?trainNo=${trainNo}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      console.log(data); // Check the structure of data received

      // Handle "Train not found" error
      if (data.data === 'Train not found') {
        setTrainInfo({ error: 'Train not found' });
        toast.warn('Train not found.');
        playNotificationSound('error');
      } else {
        setTrainInfo(data.data.data); // Store API response data in state
        toast.success(`Train information for ${data.data.data['train_no']} - ${data.data.data['train_name']} fetched successfully!`);
        playNotificationSound('success');
      }
      setError(null); // Clear any previous errors
    } catch (error) {
      setError(error.message || 'Something went wrong.');
      toast.error('Something went wrong.');
      playNotificationSound('error');
    }
  };

  const renderCard = () => {
    if (!trainInfo) {
      return null;
    }

    // Check if there's an error message indicating "Train not found"
    if (trainInfo.error === 'Train not found') {
      return (
        <div className="error-message">
          <p>Train not found.</p>
        </div>
      );
    }

    // Assuming trainInfo contains valid data to display as a card
    return (
      <div className="train-card">
        <h3>Train Information for Train No. {trainNo}</h3>
        <div className="card-content overflow-auto max-h-96 mb-40">
          <p><strong>Train Name:</strong> {trainInfo.train_name}</p>
          <p><strong>From Station:</strong> {trainInfo.from_stn_name} ({trainInfo.from_stn_code})</p>
          <p><strong>To Station:</strong> {trainInfo.to_stn_name} ({trainInfo.to_stn_code})</p>
          <p><strong>Departure Time:</strong> {trainInfo.from_time}</p>
          <p><strong>Arrival Time:</strong> {trainInfo.to_time}</p>
          <p><strong>Travel Time:</strong> {trainInfo.travel_time}</p>
          <p><strong>Type:</strong> {trainInfo.type}</p>
          <p><strong>Distance (km):</strong> {trainInfo.distance_from_to}</p>
          <p><strong>Average Speed:</strong> {trainInfo.average_speed}</p>
        </div>
      </div>
    );
  };

  const playNotificationSound = (type) => {
    let audio;
    switch (type) {
      case 'click':
        audio = new Audio(ClickSound);
        break;
      case 'server':
        audio = new Audio(ServerNotification);
        break;
      case 'success':
        audio = new Audio(SuccessNotification);
        break;
      case 'error':
        audio = new Audio(ErrorNotification);
        break;
      default:
        break;
    }

    if (audio) {
      audio.volume = 0.5; // Adjust volume if needed
      audio.play();
    }
  };

  return (
    <div className="train-info">
      <ToastContainer /> {/* Ensure ToastContainer is rendered once in your application */}
      <h2><IoMdTrain /> <RiInformationFill /> <TbNotes /> Train Information</h2>
      <div className="input-container">
        <input type="text" placeholder="Enter Train Number" value={trainNo} onChange={handleInputChange} />
        <button onClick={() => { fetchTrainInfo(); playNotificationSound('click'); }}>Get Information</button>
      </div>
      {error && <p className="error"><BiSolidErrorAlt /> {error}</p>}
      <div className="train-info-card">
        {renderCard()}
      </div>
    </div>
  );
}

export default TrainInformation;
