import React, { useState, useRef } from 'react';
import './pnr.css'; // Import your custom CSS file
import { IoMdTrain } from "react-icons/io";
import { BiSolidErrorAlt } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ClickSound from "../notification/mixkit-game-balloon-or-bubble-pop-3069.wav";
import ServerNotification from '../notification/mixkit-clear-announce-tones-2861.wav';
import SuccessNotification from '../notification/mixkit-ice-hockey-sports-buzzer-941.wav';
import ErrorNotification from '../notification/mixkit-wrong-answer-bass-buzzer-948.wav';

function PnrStatus() {
    const [pnr, setPnr] = useState(''); // State to store PNR input
    const [pnrInfo, setPnrInfo] = useState(null); // State to store API response
    const [error, setError] = useState(null); // State to store error messages

    const serverNotificationRef = useRef(null);
    const successNotificationRef = useRef(null);
    const errorNotificationRef = useRef(null);

    const handleInputChange = (e) => {
        // Limit input length to 10 characters
        const newValue = e.target.value.slice(0, 10);
        setPnr(newValue);
    };

    const fetchPnrData = async () => {
        try {
            // Validate PNR number length
            if (pnr.length !== 10) {
                setError('PNR number should be exactly 10 digits.');
                return;
            }

            const response = await fetch(`https://trainbackend.cooliewale.in/api/getPNRinfo?pnr=${pnr}`);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            console.log(data); // Check the structure of data received

            if (data.error) {
                setError(data.error);
                setPnrInfo(null); // Corrected from setPnrData
                toast.error(data.error); // Toast error message
                playNotificationSound('error');
            } else if (data.data && data.data.Error === "Flushed PNR /PNR not yet generated") {
                setPnrInfo(data.data);
                setError(data.data.Error);
                toast.warn(data.data.Error); // Toast warning message
                playNotificationSound('warn');
            } else {
                console.log(data.data);
                setPnrInfo(data.data);
                //  valid and active PNR ==> make an table 
                setError(null);
                toast.success(`PNR information fetched successfully for PNR: ${pnr}`);
                playNotificationSound('success');
            }
        } catch (error) {
            console.error("Error fetching PNR data:", error);
            setError('Failed to fetch PNR data.');
            setPnrInfo(null); // Corrected from setPnrData
            toast.error('Failed to fetch PNR data.'); // Toast error message
            playNotificationSound('error');
        }
    };

    const playNotificationSound = (type) => {
        switch (type) {
            case 'success':
                successNotificationRef.current.play();
                break;
            case 'warn':
                // Play a different sound for warning (if desired)
                break;
            case 'error':
                errorNotificationRef.current.play();
                break;
            default:
                serverNotificationRef.current.play();
                break;
        }
    };

    return (
        <div className="pnr-status">
      <ToastContainer /> {/* Ensure ToastContainer is rendered once in your application */}
      <h2>
        <IoMdTrain /> PNR Information
      </h2>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter PNR Number"
          value={pnr}
          onChange={handleInputChange}
          maxLength={10} // Restrict input length to 10 characters
        />
        <button className="fetch-button" onClick={fetchPnrData}>Get Information</button>
      </div>
      {error && <p className="error">{error}</p>}
      {pnrInfo && (
        <div className="pnr-data">
                  <div>
            <h3>Passenger Information</h3>
            <table>
              <thead>
                <tr>
                  <th>Passenger Name</th>
                  <th>Booking Status</th>
                  <th>Current Status</th>
                  <th>Coach</th>
                  {/* Add more headers as needed */}
                </tr>
              </thead>
              <tbody>
                {pnrInfo.PassengerStatus.map((passenger, index) => (
                  <tr key={index}>
                    <td>Passenger {index + 1}</td>
                    <td>{passenger.BookingStatus}</td>
                    <td>{passenger.CurrentStatus}</td>
                    <td>{passenger.Coach}</td>
                    {/* Add more data cells as needed */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <h3>Train Details</h3>
            <table>
              <tbody>
                <tr>
                  <td>Train Name:</td>
                  <td>{pnrInfo.TrainName}</td>
                </tr>

                <tr>
                  <td>Train Number:</td>
                  <td>{pnrInfo.TrainNo}</td>
                </tr>

                        <tr>
                <td> PNR  </td>
                <td> {pnrInfo.Pnr} </td>

                </tr>

                <tr>
                  <td>Date of Journey:</td>
                  <td>{pnrInfo.Doj}</td>
                </tr>

          <tr> 
          <td> Booking Date </td>
          <td> {pnrInfo.BookingDate} </td>
          </tr>

<tr>
<td> Source Date of Journey </td>
<td> {pnrInfo.SourceDoj}</td>
</tr>

<tr>
<td> Destination Date of Journey </td>
<td>{pnrInfo.DestinationDoj} </td>
</tr>
<tr>
<td>  From Station</td>
<td> {pnrInfo.From}</td>
</tr>
<tr>
<td> To Station </td>
<td> {pnrInfo.To}</td>
</tr>
<tr>
<td> Boarding Point</td>
<td> {pnrInfo.BoardingPoint}</td>
</tr>
<tr>
<td>  Reservation Upto </td>
<td> {pnrInfo.ReservationUpto}</td>
</tr>
<tr>
<td> Class</td>
<td> {pnrInfo.Class}</td>
</tr>
<tr>
<td> Boarding Station Name</td>
<td> {pnrInfo.BoardingStationName}</td>
</tr>
<tr>
<td> Reservation Upto </td>
<td> {pnrInfo.ReservationUptoName}</td>
</tr>
<tr>
<td> Chart Prepared </td>
<td> {pnrInfo.ChartPrepared}</td>
</tr>
<tr>
<td> Train Status </td>
<td>{pnrInfo.TrainStatus} </td>
</tr>
<tr>
<td>Number of Passengers </td>
<td> {pnrInfo.PassengerCount}</td>
</tr>
<tr>
<td> Departure Time</td>
<td> {pnrInfo.DepartureTime} </td>
</tr>
<tr>
<td> Arrival Time</td>
<td> {pnrInfo.ArrivalTime}</td>
</tr>
<tr>
<td> Source Name</td>
<td> {pnrInfo.SourceName}</td>
</tr>
<tr>
<td> Destination Name</td>
<td> {pnrInfo.DestinationName}</td>
</tr>
<tr>
<td> Duration</td>
<td> {pnrInfo.Duration}</td>
</tr>

<tr>
<td> Platfrom </td>
<td> {pnrInfo.ExpectedPlatformNo}</td>
</tr>

             <tr> 
                <td> Quota</td>
                <td> {pnrInfo.Quota} </td> 

                </tr> 



                {/* Add more details as needed */}
              </tbody>
            </table>
          </div>

      <div>
            <h3>Coach Position</h3>
            <table>
              <tbody>
                {pnrInfo.CoachPosition.split(' ').map((coach, index) => (
                  <th key={index}>
                    <td>{coach}</td>
                  </th>
                ))}
              </tbody>
            </table>
          </div>
          {pnrInfo.Error && <p>Error: {pnrInfo.Error}</p>}
          {/* Display other relevant PNR data here */}
        </div>
      )}
      {/* Audio elements for notification sounds */}
      <audio ref={serverNotificationRef} src={ServerNotification} style={{ display: "none" }} />
      <audio ref={successNotificationRef} src={SuccessNotification} style={{ display: "none" }} />
      <audio ref={errorNotificationRef} src={ErrorNotification} style={{ display: "none" }} />
    </div>
    );
}

export default PnrStatus;
