import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import LeftBar from './components/leftbar/LeftBar';
import Home from './Pages/home/Home';
import Footer from './components/footer/Footer';
import PnrStatus from './components/pnr/PnrStatus';
import TrainRoute from './components/trainRoute/TrainRoute';
import LiveTrain from './components/livetrain/LiveTrain';
import TrainInformation from './components/traininfo/TrainInformation';
import './App.css';

function App() {
  const [isLeftBarOpen, setIsLeftBarOpen] = useState(false);

  const toggleLeftBar = () => {
    setIsLeftBarOpen(!isLeftBarOpen);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-1">
        <LeftBar isOpen={isLeftBarOpen} toggle={toggleLeftBar} />
        <div className={`flex-1 p-5 bg-white ${isLeftBarOpen ? 'ml-0' : 'ml-0'}  md:ml-[220px] mt-24`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pnr" element={<PnrStatus />} />
            <Route path="/train-route" element={<TrainRoute />} />
            <Route path="/live-train" element={<LiveTrain />} />
            <Route path="/train-info" element={<TrainInformation />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
