import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FcAbout, FcBusinessContact } from "react-icons/fc";
import { GoHome } from "react-icons/go";
import ClickSound from "../notification/mixkit-game-balloon-or-bubble-pop-3069.wav"; // Adjust path as necessary

function Header() {
  const [clickSound] = useState(new Audio(ClickSound)); // Create audio element for the click sound

  const playClickSound = () => {
    clickSound.currentTime = 0; // Rewind to the beginning
    clickSound.play();
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-gray-900 text-white py-3 px-5 z-10 flex flex-col  items-center justify-between">
      <h1 className="font-bold text-xl mb-3 md:mb-0 pb-3">CoolieWale Train Utility</h1>
      <nav className="flex  md:flex-row mt-3 md:mt-0">
        <Link to="/" className="inline-block py-2 px-2 sm:py-2 sm:px-4 mx-2 text-sm sm:text-base text-white bg-red-600 border border-purple-500 rounded-lg transition duration-300 hover:bg-pink-600 focus:outline-none focus:ring-4 focus:ring-blue-300 mb-2 md:mb-0" onClick={playClickSound}>
          <GoHome className="inline-block mr-1 sm:mr-2" />Home
        </Link>
        <a href="https://www.cooliewale.in/aboutUs" className="inline-block py-2 px-2 sm:py-2 sm:px-4 mx-2 text-sm sm:text-base text-white bg-red-600 border border-purple-500 rounded-lg transition duration-300 hover:bg-pink-600 focus:outline-none focus:ring-4 focus:ring-blue-300 mb-2 md:mb-0" onClick={playClickSound}>
          <FcAbout className="inline-block mr-1 sm:mr-2" /> About
        </a>
        <a href="https://www.cooliewale.in/contactUs" className="inline-block py-2 px-2 sm:py-2 sm:px-4 mx-2 text-sm sm:text-base text-white bg-red-600 border border-purple-500 rounded-lg transition duration-300 hover:bg-pink-600 focus:outline-none focus:ring-4 focus:ring-blue-300 mb-2 md:mb-0" onClick={playClickSound}>
          <FcBusinessContact className="inline-block mr-1 sm:mr-2" />Contact
        </a>
      </nav>
    </header>
  );
}

export default Header;
