import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./livetrain.css"; // Make sure this imports the required CSS
import { IoMdTrain } from "react-icons/io";
import { BsCalendarDateFill } from "react-icons/bs"; // Import BsCalendarDateFill from react-icons/bs
import { BiSolidErrorAlt } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ClickSound from "../notification/mixkit-game-balloon-or-bubble-pop-3069.wav"; 
import ServerNotification from '../notification/mixkit-clear-announce-tones-2861.wav';
import SuccessNotification from '../notification/mixkit-ice-hockey-sports-buzzer-941.wav';
import ErrorNotification from '../notification/mixkit-wrong-answer-bass-buzzer-948.wav';

function LiveTrain() {
  const [trainNo, setTrainNo] = useState("");
  const [date, setDate] = useState(null);
  const [currentStation, setCurrentStation] = useState(null);
  const [liveStatus, setLiveStatus] = useState(null);
  const [error, setError] = useState(null);

  const serverNotificationRef = useRef(null);
  const successNotificationRef = useRef(null);
  const errorNotificationRef = useRef(null);

  const handleTrainNoChange = (event) => {
    setTrainNo(event.target.value);
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const fetchTrainData = () => {
    playNotificationSound('click'); // Play click sound when button is clicked

    let url = `https://trainbackend.cooliewale.in/api/liveTrainInfo?trainNo=${trainNo}`;
    if (date) {
      const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      url += `&date=${formattedDate}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.data.error) {
          setError(data.data.error);
          setCurrentStation(null);
          setLiveStatus(null);
          toast.error(data.data.error); // Toast error message
          playNotificationSound('error');
        } else {
          if (data.data.current) {
            setCurrentStation(data.data.current);
            toast.success(`Live train status fetched successfully for ${data.data.route['TrainNo']} : ${data.data.route['TrainName']} `);
            toast.success(`Currently at ${data.data.current["current station name"]} - ${data.data.current["current station code"]}!`);
            playNotificationSound('success');
          }
          if (data.data["live status"]) {
            setLiveStatus(data.data["live status"]);
          }
          setError(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching live train data:", error);
        setError("Failed to retrieve Live Train status.");
        setCurrentStation(null);
        setLiveStatus(null);
        toast.error('Failed to retrieve Live Train status.'); // Toast error message
        playNotificationSound('error');
      });
  };

  const renderCurrentStationCard = () => {
    if (!currentStation) return null;

    return (
      <div className="card">
        <h4>Current Station</h4>
        <p>
          <strong>Station Code:</strong>{" "}
          {currentStation["current station code"]}
        </p>
        <p>
          <strong>Station Name:</strong>{" "}
          {currentStation["current station name"]}
        </p>
        <p>
          <strong>Updated At:</strong> {currentStation["updated at"]}
        </p>
      </div>
    );
  };

  const renderLiveTrainRouteCard = () => {
    if (!liveStatus) return null;

    return (
      <div className="card">
        <h4>Live Train Route</h4>
        {renderLiveTrainRouteTable()}
      </div>
    );
  };

  const renderLiveTrainRouteTable = () => {
    return (
      <table className="train-table">
        <thead>
          <tr>
            <th>Station</th>
            <th>Has Passed</th>
            <th>Day</th>
            <th>Date</th>
            <th>Arrival</th>
            <th>Departure</th>
            <th>Delay</th>
          </tr>
        </thead>
        <tbody>
          {liveStatus.map((status, index) => (
            <tr key={index}>
              <td>{status.Station}</td>
              <td>{status["Has passed"].toString()}</td>
              <td>{status.Day}</td>
              <td>{status.Date}</td>
              <td>{status.Arrival}</td>
              <td>{status.Departure}</td>
              <td>{status.Delay}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const playNotificationSound = (type) => {
    let audio;
    switch (type) {
      case 'click':
        audio = new Audio(ClickSound);
        break;
      case 'server':
        audio = new Audio(ServerNotification);
        break;
      case 'success':
        audio = new Audio(SuccessNotification);
        break;
      case 'error':
        audio = new Audio(ErrorNotification);
        break;
      default:
        break;
    }

    if (audio) {
      audio.volume = 0.5; // Adjust volume if needed
      audio.play();
    }
  };

  return (
    <div className="live-train-container">
      <ToastContainer /> {/* Ensure ToastContainer is rendered once in your application */}
      <h2>
        <IoMdTrain />{" "}
        {/* Make sure TbRouteSquare is imported and used correctly */}
        <span className="blinking">LIVE Train STATUS</span>
      </h2>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter Train Number"
          value={trainNo}
          onChange={handleTrainNoChange}
        />
        <DatePicker
          selected={date}
          onChange={handleDateChange}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select Date"
          calendarIcon={<BsCalendarDateFill />}
        />
        <button onClick={fetchTrainData}>Fetch Live Status</button>
      </div>
      {error && (
        <p className="error-message">
          {" "}
          <BiSolidErrorAlt /> {error}
        </p>
      )}
      <div className="card-container">
        {renderCurrentStationCard()}
        {renderLiveTrainRouteCard()}
      </div>
      {/* Audio elements for notification sounds */}
      <audio ref={serverNotificationRef} src={ServerNotification} style={{ display: "none" }} />
      <audio ref={successNotificationRef} src={SuccessNotification} style={{ display: "none" }} />
      <audio ref={errorNotificationRef} src={ErrorNotification} style={{ display: "none" }} />
    </div>
  );
}

export default LiveTrain;
