import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HiMiniHome } from "react-icons/hi2";
import { IoMdTrain } from "react-icons/io";
import { TbRouteSquare } from "react-icons/tb";
import { BsTicketDetailed } from "react-icons/bs";
import { RiInformationFill } from "react-icons/ri";
import { TbNotes } from "react-icons/tb";
import { GiHamburgerMenu } from "react-icons/gi";
import ClickSound from "../notification/mixkit-game-balloon-or-bubble-pop-3069.wav";

function LeftBar({ isOpen, toggle }) {
  const [clickSound] = useState(new Audio(ClickSound));

  const playClickSound = () => {
    clickSound.currentTime = 0;
    clickSound.play();
  };


  return (
    <div>
      <button
        className="md:hidden fixed top-16 left-4 z-50 text-white bg-indigo-700 p-2 rounded"
        onClick={toggle}
      >
        <GiHamburgerMenu size={24} />
      </button>
      <nav
        className={`fixed top-[60px] bottom-[40px] left-0 bg-indigo-700 backdrop-blur-lg text-white w-52 p-5 z-[1] overflow-y-auto transition-transform duration-300 ${isOpen ? 'transform translate-x-0' : 'transform -translate-x-full'} md:translate-x-0`}
        
      >
        <ul className="list-none p-0 mt-24">
          <li className="mb-2.5">
            <Link to="/" className=" block bg-red-600 hover:bg-pink-600 text-white py-2.5 px-3.5 rounded transition duration-300" onClick={playClickSound}>
              <HiMiniHome className="inline-block mr-2" />
              Home
            </Link>
          </li>
          <li className="mb-2.5">
            <Link to="/pnr" className="block bg-red-600 hover:bg-pink-600 text-white py-2.5 px-3.5 rounded transition duration-300" onClick={playClickSound}>
              <IoMdTrain className="inline-block mr-2" />
              <BsTicketDetailed className="inline-block mr-2" />
              PNR
            </Link>
          </li>
          <li className="mb-2.5">
            <Link to="/train-route" className="block bg-red-600 hover:bg-pink-600 text-white py-2.5 px-3.5 rounded transition duration-300" onClick={playClickSound}>
              <IoMdTrain className="inline-block mr-2" />
              <TbRouteSquare className="inline-block mr-2" />
              Train Route
            </Link>
          </li>
          <li className="mb-2.5">
            <Link to="/live-train" className="block bg-red-600 hover:bg-pink-600 text-white py-2.5 px-3.5 rounded transition duration-300" onClick={playClickSound}>
              <IoMdTrain className="inline-block mr-2" />
              <TbRouteSquare className="inline-block mr-2" />
              Live Train
            </Link>
          </li>
          <li className="mb-2.5">
            <Link to="/train-info" className="block bg-red-600 hover:bg-pink-600 text-white py-2.5 px-3.5 rounded transition duration-300" onClick={playClickSound}>
              <IoMdTrain className="inline-block mr-2" />
              <RiInformationFill className="inline-block mr-2" />
              <TbNotes className="inline-block mr-2" />
              Train Information
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default LeftBar;
